/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/main'
import { query } from '../utils/style'
import SEO from '../components/SEO'

const crumbs = [
  {
    title: 'kristo jorgenson',
    slug: '/',
  },
]

export default ({ data, ...rest }) => {
  const posts = data.allMdx.edges
  return (
    <Layout crumbs={crumbs} background="accent">
      {/*<Head>
      <title>projects</title>
    </Head>*/}
      <SEO title="Home" />

      <Projects>
        {posts.map(
          ({
            node: {
              id,
              fields: { slug },
              frontmatter: { date, title },
            },
          }) => (
            <Project id={id} key={id} date={date} title={title} slug={slug} />
          )
        )}
      </Projects>
    </Layout>
  )
}

const Projects = styled.div`
  padding: 25px 20px;
  flex: 1 0 0px;
  ${query(500)`
    padding: 25px 50px;
  `};
`
const Project = ({ id, date, title, slug }) => (
  <Wrap>
    <Date>{date}</Date>
    <Styled.a as={Link} to={`${slug}`} sx={{ variant: 'links.primary' }}>
      {title}
    </Styled.a>
  </Wrap>
)

const Wrap = styled.div`
  margin-bottom: 15px;
  ${query(500)`
    margin-bottom: 10px;
  `};
`

const Date = styled.span`
  display: block;
  width: inherit;
  text-align: inherit;
  font-size: 11px;
  color: #ccc;
  margin-bottom: 5px;

  ${query(500)`
    display: inline-block;
    width: 140px;
    text-align: right;
    margin-right: 30px;
    color: #999;
  `};
`

export const pageQuery = graphql`
  query pages {
    allMdx {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(fromNow: true)
          }
        }
      }
    }
  }
`
